import { useRouter }                   from "next/router";
import { useEffect, useRef, useState } from "react";
import videojs                         from "video.js";

import { useGetProStatus }     from "_common/hooks/auth";
import { useGetAdWatchStatus } from "_jotai/rooterPro";

import { useSetCurrentTime, useSetIsPaused, useSetIsPlaying } from "./states";

import "videojs-contrib-ads";
import "videojs-ima";

// change this value for free streams and pro users who are watching the pro streams
// ideally they should have autoplay turned on
const defaultOptions = {
	preload                   : "auto",
	controls                  : false,
	responsive                : true,
	suppressNotSupportedError : true,
	errorDisplay              : false,
	html5                     : {
		vhs: {
			enableLowInitialPlaylist         : true,
			limitRenditionByPlayerDimensions : true,
			useBandwidthFromLocalStorage     : true,
			bandwidth                        : 1048576// 1 mbps
		}
	}
};

const VideoJS = props => {
	const {
		src,
		poster,
		onReady,
		duplicatePlayback = () => { },
		onDataLoaded = () => { },
		onPlaying = () => { },
		onEnded = () => { },
		onAdLoad = () => { },
		onDestroy,
		options,
		playerCss,
		restrictQuality,
		isPlayingAd
	} = props;

	const router                      = useRouter ();
	const videoNode                   = useRef ( null );
	const adWatchStatus               = useGetAdWatchStatus ();
	const playerRef                   = useRef ( null );
	const setCurrentTime              = useSetCurrentTime ();
	const setIsPlaying                = useSetIsPlaying ();
	const [ isWaiting, setIsWaiting ] = useState ( false );
	const setIsPaused                 = useSetIsPaused ();
	const proStatus                   = useGetProStatus ();
	const changedKey                  = `${ src }-${ router?.pathname }`;

	const updateTimer = () => {
		const currTime = playerRef.current.currentTime ();

		setCurrentTime ( currTime );
	};

	const onCanPlay = () => {
		setIsPaused ( playerRef.current.paused () );

		// Remove the loader when the video is ready to play
		playerRef.current.removeClass ( "vjs-loading" );
	};

	const onXHRResponse = evt => {
		if ( evt.status === 403 && proStatus ) {
			// change this to check if pro user instead of response
			duplicatePlayback ( true );
		}
	};

	useEffect ( () => {
		if ( !playerRef?.current )
			return;

		playerRef.current.rooterCustoms["isPlayingAd"] = isPlayingAd;
	}, [ isPlayingAd, playerRef ] );

	useEffect ( () => {
		if ( adWatchStatus.finished )
			playerRef.current?.play ();
	}, [ adWatchStatus.finished ] );

	useEffect ( () => {
		videojs.log.level ( "error" );

		playerRef.current = videojs ( videoNode.current, { ...defaultOptions, ...options }, () => {
			setIsPaused ( playerRef.current.paused () );
			onReady && onReady ( playerRef );
		} );

		onAdLoad ( playerRef );

		// Adding a custom object
		playerRef.current.rooterCustoms = {};

		playerRef.current.one ( "loadeddata", () => {

			onDataLoaded ( playerRef );

			if ( restrictQuality ) {
				const resolutions = playerRef.current.tech ()?.vhs?.representations () || [];

				if ( !resolutions || !resolutions.length )
					return;

				resolutions.forEach ( quality => {
					if ( quality.height === 1080 )
						quality.enabled ( false );
					else
						quality.enabled ( true );
				} );

				playerRef.current.rooterCustoms["restrictQuality"] = true;
			}

			playerRef.current.tech ()?.vhs?.xhr.onResponse ( onXHRResponse );
		} );

		playerRef.current.on ( "timeupdate", updateTimer );

		playerRef.current.on ( "playing", () => {
			if ( !isWaiting ) {
				setIsPaused ( playerRef.current.paused () );
				onPlaying ( playerRef );
				setIsPlaying ( true );
			}
		} );

		playerRef.current.on ( "waiting", () => {
			setIsWaiting ( true );
			setIsPlaying ( false );
		} );

		playerRef.current.on ( "canplaythrough", () => {
			setIsWaiting ( false );
			setIsPlaying ( true );
		} );

		playerRef.current.on ( "pause", () => {
			setIsPlaying ( false );
		} );

		playerRef.current.on ( "canplay", onCanPlay );

		playerRef.current.on ( "ended", onEnded );

		// Dispose the Video.js player
		return () => {
			if ( playerRef.current && !playerRef.current.isDisposed_ ) {
				onDestroy ();
				playerRef.current.dispose ();
				playerRef.current = null;
				// playerRef.current.off ( "timeupdate", updateTimer );
			}
		};
	}, [ changedKey ] );

	return (
		<div
			key = { changedKey }
			data-vjs-player
			style = { { ...playerCss, borderRadius: "inherit" } }
		>
			<div className = "vjs-loading-spinner"></div>

			<video
				ref = { videoNode }
				playsInline
				className = "video-js vjs-big-play-centered"
				id = "video-js-player"
				poster = { poster }
				style = { {
					borderRadius: "inherit"
				} }
			/>
		</div>
	);
};

export default VideoJS;
