import { Flex, Menu, ScrollArea } from "@mantine/core";

import { useGetProStatus }    from "_common/hooks/auth";
import { useOpenProModal }    from "_common/hooks/useOpenProModal";
import { PrimaryButton }      from "_components/atoms/button";
import Icon                   from "_components/atoms/icon";
import { useSetOnProSuccess } from "_jotai/rooterPro";

import useStyles from "./styles";

const PRO_QUALITIES = [
	1080
];

const component = ( {
	qualities,
	setQuality,
	currQuality,
	onClose,
	isStreamPage
} ) => {

	return (

		<ScrollArea>
			{
				qualities.map ( quality => {
					if ( !quality )
						return null;

					return (
						<QualityItem
							key = { quality }
							currQuality = { currQuality }
							isStreamPage = { isStreamPage }
							onClose = { onClose }
							quality = { quality }
							setQuality = { setQuality }
						/>
					);

				} )
			}

			<QualityItem
				currQuality = { currQuality }
				onClose = { onClose }
				quality = { "auto" }
				setQuality = { setQuality }
			/>

		</ScrollArea>
	);
};

const QualityItem = props => {
	const {
		currQuality,
		setQuality,
		onClose,
		quality,
		isStreamPage
	} = props;
	const openProModal    = useOpenProModal ();
	const setOnProSuccess = useSetOnProSuccess ();
	const isProUser       = useGetProStatus ();

	return (
		<Menu.Item
			key = { quality }
			data-selected = { currQuality === quality || undefined }
			onClick = { () => {
				if ( PRO_QUALITIES.includes ( quality ) && !isProUser && isStreamPage ) {

					setOnProSuccess ( {
						function: () => {

							setQuality ( quality );
							onClose ();

						}
					} );

					openProModal ();

					return;
				}
				setQuality ( quality );
				onClose ();
			} }
			rightSection = { currQuality === quality ? (
				<Icon sameInBothTheme
					name = "checkmarkGradient"
					size = "2.4rem"
				/>
			)
				: null }
			selected = { currQuality === quality }
		>
			<Flex
				align = "center"
				gap = "1rem"
			>

				{quality === "auto" ? "Auto" : `${ quality }p`}

				{PRO_QUALITIES.includes ( quality ) && !isProUser && isStreamPage ? <ProTag /> : null}
			</Flex>
		</Menu.Item>
	);
};

const ProTag = () => {
	const { classes } = useStyles ( {}, { name: "qualitySelector" } );

	return (
		<PrimaryButton
			className = { classes.proButton }
			size = "small"
			startIcon = { (
				<Icon
					sameInBothTheme
					name = "proShineWhite"
					size = { "1.2rem" }
				/>
			) }
			title = { "Pro" }
		/>
	);
};

export default component;
