import { forwardRef } from "react";

import Icon        from "_components/atoms/icon";
import ToolTip     from "_components/atoms/tooltip";
import usePlayback from "_components/features/player/hooks/usePlayback";
import {
	useGetHasError
} from "_components/features/player/states";

const Controls = forwardRef ( ( {
	settingsMenu,
	setOpenSettingsMenu,
	setOpenPlaybackSpeedMenu,
	setOpenQualityMenu
}, ref ) => {

	const {
		setQualityOptions
	} = usePlayback ();

	const hasError = useGetHasError ();

	return (
		<ToolTip
			arrow
			disableLightMode
			placement = "top"
			title     = "Settings"
		>
			<button
				ref = { ref }
				aria-disabled = { hasError }
				className = "player-controls-btn"
				id = "player-control-settings"
				onPointerDown = { () => {
					setQualityOptions ();
					setOpenSettingsMenu ( !settingsMenu );
					setOpenPlaybackSpeedMenu ( false );
					setOpenQualityMenu ( false );
				} }
			>
				<Icon sameInBothTheme
					name      = "settingsIcon"
				/>
			</button>
		</ToolTip>
	);
} );

Controls.displayName = Controls;
export default Controls;
