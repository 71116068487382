import dynamic                              from "next/dynamic";
import { useCallback, useEffect, useState } from "react";

import { useGetDeviceType }                         from "_common/hooks/global";
import toast                                        from "_common/toast";
import { getOptImage }                              from "_common/utils";
import Render                                       from "_components/atoms/render";
import ProBar                                       from "_components/features/player/proBar";
import { STREAM_TIER }                              from "_components/pages/proPage/constants";
import { VIDEO_AD_TAG_URL }                         from "_config/index";
import { useSetDuplicatePlayback }                  from "_jotai/compulsoryLogin";
import { useGetAdWatchStatus, useSetAdWatchStatus } from "_jotai/rooterPro";

import Ads                                      from "./ads";
import Controls                                 from "./controls";
import useMobileView, { useMobileClickHandler } from "./hooks/useMobileView";
import usePlayback                              from "./hooks/usePlayback";
import usePlayer                                from "./hooks/usePlayer";
import Video                                    from "./player";
import {
	useGetIsMute,
	useGetLiveRef,
	useResetAtoms,
	useSetIsPaused,
	useSetIsPlaying,
	useSetLiveRef
	// useGetFullscreen
} from "./states";

const VOD = dynamic ( () => import ( "./vod" ) );

const App = props => {

	const {
		src,
		vodSrc,
		liveViews,
		startedAt,
		thumbnail,
		noControls,
		fadeReelControls,
		seekFeature,
		isStreamer,
		overridePlayerMute,
		muted = false,
		aspectRatio,
		onPlaybackEnd,
		onMute,
		onReady,
		isReel,
		type,
		isLive,
		disableTheatreMode,
		disableControlSettings,
		customControls,
		containerClass = "",
		blockInternalClick = false,
		page,
		disableFullScreen,
		fromVideoCard,
		onClick,
		remainingAdCount,
		playerCss,
		showControlsOnVideoEnd = false, // from videos,
		thumbnailType = "xxhdpi",
		startTime = 0,
		restrictQuality,
		enableAds = false,
		loop = false,
		overrideAdTag,
		handleCancelPlayNext = () => { }, // from videos
		style,
		streamTier,
		proDetails,
		isOwnStream
	} = props;

	const setIsPlaying = useSetIsPlaying ();

	// const fullscreen                              = useGetFullscreen ();
	const enableSeek = seekFeature && isLive;

	// console.log ( "App ========== l:", { enableSeek, seekFeature, isLive } );
	const prerollAdTag = overrideAdTag || VIDEO_AD_TAG_URL;

	const streamSrc                               = src;
	const deviceType                              = useGetDeviceType ();
	const isMobile                                = deviceType === "mobile";
	const isMuted                                 = useGetIsMute ();
	const setIsPaused                             = useSetIsPaused ();
	const setAdWatchStatus                        = useSetAdWatchStatus ();
	const adWatchStatus                           = useGetAdWatchStatus ();
	const poster                                  = getOptImage ( thumbnail, thumbnailType );
	const { togglePlayback }                      = usePlayback ();
	const livePlayer                              = usePlayer ( { startTime, streamSrc } );
	const setLivePlayerRef                        = useSetLiveRef ();
	const liveRef                                 = useGetLiveRef ();
	const resetAllStates                          = useResetAtoms ();
	const setDuplicatePlayback                    = useSetDuplicatePlayback ();
	const { isMobileView }                        = useMobileView ();
	const [ isControlsOpen, setIsHovered ]        = useState ( false );
	const { isControlsOpen: isMobileControlOpen } = useMobileClickHandler ();

	const [ isPlayingAd, setIsPlayingAd ] = useState ( false );

	const setDurationAndCurrentTime = player => {
		const duration = player?.current?.duration ();

		livePlayer.setDuration ( duration );

		if ( startTime && startTime < duration ) {
			livePlayer.setCurrentTime ( startTime );
			player.current.currentTime ( startTime );
		}
	};

	const onPlayerReady = player => {
		setLivePlayerRef ( player );

		const autoPlay = streamTier?.toString () === STREAM_TIER.MID_STREAM && adWatchStatus.opened ? false : true;

		if ( !autoPlay )
			return;

		player.current.play ();
	};

	const onAdLoad = player => {
		if ( !adConfig?.enabled || !window?.google?.ima )
			return;

		const imaOptions = {
			autoPlayAdBreaks : false,
			adTagUrl         : adConfig.adTag
		};

		player.current.ima ( imaOptions );

		player.current.on ( "adError", () => {
			toast.error ( "Something went wrong, please try again later!" );
			// call when ad errors out
		} );

		player.current.on ( "adstart", () => {
			// call when ad started
			setIsPlayingAd ( true );
			setIsPlaying ( false );
		} );

		player.current.on ( "adend", () => {
			setIsPlayingAd ( false );
			// setIsPlaying ( true );
			// call when ad ended
			player.current.play ();

			// removing ad container after ad ends
			const adContainer = document.getElementById ( "video-js-player_ima-ad-container" );

			if ( adContainer ) {
				adContainer.parentNode.removeChild ( adContainer );
			}
		} );

	};

	const onPlaying = player => {
		var ima = player.current.ima;

		if ( typeof ima === "function" )
			return;

		ima.playAdBreak ();

		/* if ( typeof ima?.setContentWithAdTag === "function" )
          ima?.setContentWithAdTag ( streamSrc, prerollAdTag );

        if ( typeof ima?.requestAds === "function" )
          ima?.requestAds ();*/

	};

	const onEnded = () => {
		setIsPaused ( true );
		onPlaybackEnd ();
	};

	const onDataLoaded = player => {
		setDurationAndCurrentTime ( player );
	};

	useEffect ( () => {
		if ( !liveRef?.current )
			return;

		liveRef.current.muted ( muted );
	}, [ overridePlayerMute, muted ] );

	const onPlayerDestroy = () => {
		setLivePlayerRef ( null );
		resetAllStates ();
		setIsPlaying ( false );
	};

	const options = {
		sources: [ {
			src  : streamSrc,
			type : type === "mp4" ? "video/mp4" : "application/x-mpegURL"
		} ],
		muted          : overridePlayerMute ? muted : isMuted,
		loadingSpinner : false,
		aspectRatio    : aspectRatio,
		loop
	};

	const adConfig = {
		enabled : proDetails?.isProUser ? false : enableAds,
		adTag   : prerollAdTag
	};

	const onVideoClickHandler = useCallback ( () => {
		if ( isMobile || blockInternalClick || isPlayingAd )
			return;

		togglePlayback ();
	}, [ togglePlayback, isMobile, isPlayingAd ] );

	let moveTimeout;

	const onMouseEnterHandler = useCallback ( () => {
		setIsHovered ( true );

		if ( moveTimeout )
			clearTimeout ( moveTimeout );

		moveTimeout = setTimeout ( () => {
			setIsHovered ( false );
		}, 2000 );

	}, [ setIsHovered ] );

	const onMouseLeaveHandler = useCallback ( () => {
		setIsHovered ( false );
	}, [ setIsHovered ] );

	const onClickPlayer = useCallback ( () => {
		if ( typeof ( onClick ) === "function" ) {
			onClick ( { currentTime: parseInt ( livePlayer.currentTime ) } );
		}
	}, [ livePlayer.currentTime ] );

	function showWatchAd () {
		setAdWatchStatus ( p => ( {
			...p,
			opened: true
		} ) );
	}

	function fadeProBar () {

		if ( proDetails?.isProUser )
			return !( isControlsOpen || isMobileControlOpen );

		if ( streamTier?.toString () === STREAM_TIER.MID_STREAM ) {
			if ( remainingAdCount < 1 ) {
				return true;
			}

			if ( adWatchStatus.finished )
				return true;
			else
				return false;
		}
	}

	const showProTag = () => {

		if ( proDetails?.isProUser && streamTier?.toString () === STREAM_TIER.MID_STREAM )
			return false;

		return isLive && proDetails;
	};

	return (
		<div
			{ ...( !isMobileView
				? {
					onMouseMove  : onMouseEnterHandler,
					onMouseLeave : onMouseLeaveHandler
				}
				: {} ) }
			className = { containerClass }
			id = "complete-video-player"
			onClick = { onClickPlayer }
			style = { {
				...style,
				aspectRatio  : "inherit",
				overflow     : "hidden",
				borderRadius : "inherit",
				height       : "100%",
				width        : "100%",
				position     : "relative"
			} }
		>

			<Render condition = { enableAds }>
				<Ads />
			</Render>

			<div
				onClick = { onVideoClickHandler }
				style = { { height: "100%", width: "100%" } }// , visibility: isPlayingAd ? "hidden" : "visible" } }
			>

				<Video
					adConfig = { adConfig }
					duplicatePlayback = { setDuplicatePlayback }
					onAdLoad = { onAdLoad }
					onDataLoaded = { onDataLoaded }
					onDestroy = { onPlayerDestroy }
					onEnded = { onEnded }
					onPlaying = { onPlaying }
					onReady = { onPlayerReady }
					options = { options }
					playerCss = { playerCss }
					poster = { poster }
					restrictQuality = { restrictQuality }
					src = { streamSrc }
				/>

				{enableSeek ? (
					<VOD
						aspectRatio = { aspectRatio }
						muted = { muted }
						restrictQuality = { restrictQuality }
						src = { vodSrc }
					/>
				) : null}
			</div>

			<div
				style = { { visibility: isPlayingAd ? "hidden" : "visible" } }
			>

				<Render condition = { showProTag () }>
					<ProBar
						inProp = { !fadeProBar () }
						proExpiring = { proDetails?.proExpiring }
						showTimer = { !isOwnStream }
						showWatchAd = { showWatchAd }
						streamTier = { streamTier?.toString () }
						timeLeftInSeconds = { proDetails?.nonProRemainingTime }
					/>
				</Render>

				<Controls
					currentTime = { livePlayer.currentTime }
					customControls = { customControls }
					disableControlSettings = { disableControlSettings }
					disableFullScreen = { disableFullScreen }
					disableTheatreMode = { disableTheatreMode }
					duration = { livePlayer.duration }
					fadeReelControls = { fadeReelControls }
					fromVideoCard = { fromVideoCard }
					handleCancelPlayNext = { handleCancelPlayNext }
					handleVideoClick = { () => { } } // isLive ? handleLiveVideoClick : handleEndedVideoClick }
					isControlsOpen = { isControlsOpen || isMobileControlOpen }
					isLive = { isLive }
					isReel = { isReel }
					isStreamer = { isStreamer }
					liveViews = { liveViews }
					noControls = { noControls }
					onClick = { onClick }
					onMute = { onMute }
					onReady = { onReady }
					page = { page }
					seekFeature = { seekFeature }
					setCurrentTime = { livePlayer.setCurrentTime }
					showControlsOnVideoEnd = { showControlsOnVideoEnd }
					startedAt = { startedAt }
				/>
			</div>
		</div>
	);
};

export default App;
