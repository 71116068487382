import { memo } from "react";

import Icon        from "_components/atoms/icon";
import ToolTip     from "_components/atoms/tooltip";
import usePlayback from "_components/features/player/hooks/usePlayback";
import {
	useGetFullscreen,
	useGetHasError
} from "_components/features/player/states";

const Controls = ( ) => {
	const {
		toggleFullscreen
	} = usePlayback ();

	const hasError   = useGetHasError ();
	const fullscreen = useGetFullscreen ();

	return (
		<ToolTip
			arrow
			disableLightMode
			placement = "top"
			title     = { fullscreen ? "Exit Full screen (f)" : "Full screen (f)" }
		>
			<button
				aria-disabled = { hasError }
				className = "player-controls-btn"
				onPointerDown = { toggleFullscreen }
			>
				{ fullscreen ?
					(
						<Icon sameInBothTheme
							name      = "fullscreenExitIcon"
						/>
					) : (
						<Icon sameInBothTheme
							name      = "fullscreenEnterIcon"
						/>
					)}
			</button>
		</ToolTip>
	);
};

export default memo ( Controls );
