import { useEffect, useState } from "react";

import CustomSlider from "../customSlider";

import useStyles from "./styles";

const SeekBar = ( { duration, currentTime, onSeek, stopAnimation } ) => {

	const { classes } = useStyles ( undefined, { name: "styles" } );

	const [ percent, setPercent ] = useState ( 0 );

	const updateProgressBar = () => {
		const calPercent = ( 100 / duration ) * currentTime;

		setPercent ( calPercent );
	};

	useEffect ( () => {
		if ( !currentTime )
			return;

		updateProgressBar ();
	}, [ currentTime ] );

	const onChange = val => {
		onSeek ( parseFloat ( val ) );
	};

	return (
		<CustomSlider
			containerClass = { classes.containerClass }
			max = { duration }
			min = "0"
			onChange = { onChange }
			percent = { percent }
			step = { "0.00001" }
			stopAnimation = { stopAnimation }
			value = { currentTime }
		/>
	);
};

export default SeekBar;
