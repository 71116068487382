import { memo } from "react";

import Icon        from "_components/atoms/icon";
import Render      from "_components/atoms/render";
import ToolTip     from "_components/atoms/tooltip";
import usePlayback from "_components/features/player/hooks/usePlayback";
import {
	useGetHasError,
	useGetIsPaused
} from "_components/features/player/states";

const Controls = () => {
	const { togglePlayback } = usePlayback ();
	const hasError           = useGetHasError ();
	const isPaused           = useGetIsPaused ();

	return (
		<ToolTip
			arrow
			disableLightMode
			placement = "top"
			title = { isPaused ? "Play (space)" : "Pause (space)" }
		>
			<button
				aria-disabled = { hasError }
				className = { `player-controls-btn play-pause-btn ${ isPaused ? "play-btn" : "pause-btn"
				}` }
				onPointerDown = { togglePlayback }
				// onClick = { handleVideoClick }
				// style = { { marginRight: "15px", width: "22px" } }
			>
				<Render condition = { isPaused }>
					<Icon sameInBothTheme
						name = "videoPlayIcon"
					/>
				</Render>

				<Render condition = { !isPaused }>
					<Icon sameInBothTheme
						name = "videoPauseIcon"
					/>

				</Render>
			</button>
		</ToolTip>
	);
};

export default memo ( Controls );
