import { Menu, ScrollArea } from "@mantine/core";

import Icon from "_components/atoms/icon";

const component = ( {
	setSpeed,
	currSpeed,
	onClose
} ) => {
	const playbackRateArr = [ 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2 ];

	return (

		<ScrollArea h = { 350 }>
			{
				playbackRateArr.map ( speed => {

					return (
						<SpeedItem
							key       = { speed }
							currSpeed = { currSpeed }
							onClose   = { onClose }
							setSpeed  = { setSpeed }
							speed     = { speed }
						/>

					);
				} )
			}
		</ScrollArea>
	);
};

const SpeedItem = props => {
	const {
		currSpeed,
		setSpeed,
		speed,
		onClose
	} = props;
	const selected = currSpeed === speed || speed === 1 && currSpeed === "normal";

	return (
		<Menu.Item
			key           = { speed }
			data-selected = { selected || undefined }
			onClick       = { () => {
				setSpeed ( speed );
				onClose ();
			} }
			rightSection = { selected ? (
				<Icon sameInBothTheme
					name = "checkmarkGradient"
					size = "2.4rem"
				/>
			)
				: null }
			selected = { selected }
		>

			{speed === 1 ? "Normal" : `${ speed }`}

		</Menu.Item>
	);
};

export default component;
