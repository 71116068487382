import { Slider }              from "@mantine/core";
import { useTimeout }          from "@mantine/hooks";
import { useEffect, useState } from "react";

import useStyles from "./styles";

const component = ( {
	onChange,
	// value,
	containerClass = "",
	// rangeInputClass="",
	// filledBarClass="",
	max = 100,
	// min="0",
	percent = 0,
	// step="1",
	stopAnimation,
	disableAmination,
	onMouseEnter,
	onMouseLeave
} ) => {

	const [ disableAnination, setDisableAnimation ] = useState ( false );
	const { start: startTimer, clear: clearTimer }  = useTimeout ( () => setDisableAnimation ( false ), 1 );

	const { classes } = useStyles ( {}, { name: "customSlider" } );

	useEffect ( () => {
		if ( stopAnimation ) {
			setDisableAnimation ( true );
			startTimer ();
		}

		return clearTimer;

	}, [ stopAnimation ] );

	return (
		<Slider
			className    = { `${ classes.slider } ${ disableAnination || disableAmination ? classes.disableTransition : "" } ${ containerClass }` }
			label = { null }
			onChange     = { e => {
				const val   = e;
				const value = max * ( val / 100 );

				onChange ( value );
				setDisableAnimation ( true );
				startTimer ();

			} }
			onMouseEnter = { onMouseEnter }
			onMouseLeave = { onMouseLeave }
			showLabelOnHover = { false }
			value        = { percent }
		/>

	);

};

export default component;

// custom slider
{ /* return (
	<div
		className = { `${ classes.barContainer } ${ containerClass }` }
		onMouseEnter = { onMouseEnter }
		onMouseLeave = { onMouseLeave }
	>
		<input
			aria-orientation = "horizontal"
			autoComplete     = "off"
			className        = { `${ classes.rangeInput } ${ rangeInputClass }` }
			max              = { max }
			min              = { min }
			onChange         = { onChangeHandler }
			step             = { step }
			type             = "range"
			value            = { value }
		/>

		<div
			className = { `${ classes.filledBar } ${ filledBarClass } filledBar1` }
			style     = { {
				width: percent+"%"
				// ...( disableAnination && { transition: "none" } )
			} }
		>
		</div>

		 <div className = { classes.baseBar }>
			<div
				className = { `${ classes.filledBar } ${ filledBarClass } filledBar1` }
				style     = { {
					width: percent+"%",
					...( disableAnination && { transition: "none" } )
				} }
			>
			</div>
		</div>
	</div>
); */ }
